import React from "react";
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { UpdateTemplateNavigation, UpdateTemplateWindow } from 'redux/actions/template';

const Navigation = ({t, handleClick}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentPath = location.pathname;
    return(
        <div className="k-navigation">
            <ul>
                <li 
                    className={cn({ current: currentPath === '/SportsPlatform'})}
                    onClick={() => handleClick('/SportsPlatform')}
                >
                        {t('nav.sports.platform')}
                </li>
                {/* <li 
                    className={cn({ current: currentPath === '/CasinoPlatform'})}
                    onClick={() => handleClick('/CasinoPlatform')}
                >
                        {t('nav.casino.platform')}
                </li> */}
                <li 
                    className={cn({ current: currentPath === '/LightningPlatform'})}
                    onClick={() => handleClick('/LightningPlatform')}
                >
                        {t('nav.lightning.platform')}
                </li>
                <li 
                    className={cn({ current: currentPath === '/GlobalPlatform'})}
                    onClick={() => handleClick('/GlobalPlatform')}
                >
                        {t('nav.global.platform')}
                </li>
                <li
                    className={cn({ current: currentPath === '/InsightForge'})}
                    onClick={ () => {
                        handleClick('/InsightForge');
                        dispatch(UpdateTemplateNavigation('mobile'));
                        dispatch(UpdateTemplateWindow(false));
                    }}
                >
                    {t('nav.InsightForge')}
                </li>
                {/* <li
                    className={cn({ current: currentPath === '/DomainAnalyzer'})}
                    onClick={ () => {
                        handleClick('/DomainAnalyzer');
                        dispatch(UpdateTemplateNavigation('mobile'));
                        dispatch(UpdateTemplateWindow(false));
                    }}
                >
                    {t('nav.DomainAnalyzer')}
                </li> */}
                <li
                    className={cn({ current: currentPath === '/Template'})}
                    onClick={ () => {
                        handleClick('/Template');
                        dispatch(UpdateTemplateNavigation('mobile'));
                        dispatch(UpdateTemplateWindow(false));
                    }}
                >
                    {t('nav.template')}
                </li>
                
            </ul>
        </div>
    )
};

export default withTranslation()(Navigation);