import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useShallowEqual from 'libraries/hooks/useShallowEqual';
import { withTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { isMobile } from 'libraries/device';
import 'swiper/css';
import 'swiper/css/autoplay';
import { UpdateTemplateNavigation, UpdateTemplateWindow } from 'redux/actions/template';
const providerList = [
  'ag',
  'allbet',
  'ameba',
  'aplus',
  'aux',
  'bbin',
  'bo',
  'bti',
  'card365',
  'cmdbet',
  'cq9',
  'crpati',
  'dg',
  'digmaan',
  'dj88',
  'ebet',
  'esportsbull',
  'evo',
  'evoplay',
  'ezb68',
  'ezugi',
  'fc',
  'gen',
  'goldenhero',
  'gpi',
  'gw',
  'ha',
  'jdb',
  'jili',
  'jm',
  'joker',
  'k36_sports',
  'ka',
  'kingmaker',
  'kx',
  'ky',
  'lc',
  'luckypoker',
  'ludo',
  'mg',
  'muaystep',
  'mw',
  'nolimit',
  'ob_mpg',
  'ob_sports',
  'og',
  'pg',
  'png',
  'pp',
  'ps',
  'pt',
  'pushgaming',
  'rcb',
  'relaxgaming',
  'rich88',
  'rt',
  'rw',
  'rwy',
  'sa',
  'saba_esports',
  'saba',
  'sbobet',
  'sea',
  'sexybcrt',
  'simpleplay',
  'skm',
  'spadegaming',
  'spr168',
  'sv',
  'tcg',
  'tf',
  'tp',
  'ufabet',
  'ufaslot',
  'ugaming',
  'venus',
  'vg',
  'vr',
  'vt',
  'wm',
  'yggdrasil',
  'yl',
];

const Home = ({ t }) => {
  const {  isSupportWebp } = useShallowEqual((state) => ({
    isSupportWebp: state.isSupportWebp,
  }));
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const section = document.querySelector(hash);
      if (section) {
        section.scrollIntoView({ behavior: 'instant' });
      }
    }
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const desktopNavigationHandle = (route) => {
    navigate(route);
    window.scrollTo(0, 0);
  };
  const featureRenderer = () => {
    var featureList = [];
    for (var i = 1; i < 9; i++) {
      featureList.push(
        <div key={`home-feature${i}-item`} className='feature-item'>
          <img src={`/img/feature${i}.svg`} alt={`feature${i}`} loading='lazy' />
          <h4>{t(`home.feature${i}.title`)}</h4>
          <p>{t(`home.feature${i}.desc`)}</p>
        </div>
      );
    }
    return featureList;
  };
  const demoRenderer = () => {
    var demoList = [];
    for (var i = 1; i < 11; i++) {
      demoList.push(
        <div key={`home-demo${i}-item`} className='demo-item'>
          <div className='main-content'>
            <h4>{t(`home.demo${i}.title`)}</h4>
            <p>{t(`home.demo${i}.desc`)}</p>
          </div>
        </div>
      );
    }
    return demoList;
  };

  const mp4File = isMobile ? '/img/home-bg-m.mp4': '/img/home-bg.mp4';
  const pngFile = isSupportWebp ? 'webp' : 'png';

  return (
    <div className='page-home'>
      <div className='k-btn-toggle-group'>
        <div className='k-btn-toggle-wrap'>
          <div className='k-btn-toggle' onClick={() => desktopNavigationHandle('/SportsPlatform')}>{t('nav.sports.platform')}</div>
          <div className='k-btn-sepline'></div>
          
          <div className='k-btn-toggle' onClick={() => desktopNavigationHandle('/LightningPlatform')}>{t('nav.lightning.platform')}</div>
          <div className='k-btn-sepline'></div>
          <div className='k-btn-toggle' onClick={() => desktopNavigationHandle('/GlobalPlatform')}>{t('nav.global.platform')}</div>
          <div className='k-btn-sepline'></div>
          <div className='k-btn-toggle' onClick={() => desktopNavigationHandle('/InsightForge')}>{t('nav.InsightForge')}</div>
        </div>
      </div>
      <section className='home-top'>
        <div className='main-wrap'>
          <div className='left'>
            <div className='small-text-tag'>{t('home.top.tag')}</div>
            <h1>{t('home.top.title')}</h1>
            <p>{t('home.top.desc')}</p>
            <div className='button-group'>
              <button className='k-btn btn-l btn-primary' onClick={() => desktopNavigationHandle('/ContactUs')}>
                {t('home.top.btn.contact')}
              </button>
              <button
                className='k-btn btn-l btn-outline-primary'
                onClick={() => {
                  desktopNavigationHandle('/Template');
                  dispatch(UpdateTemplateNavigation('mobile'));
                  dispatch(UpdateTemplateWindow(false));
                }}
              >
                {t('home.top.btn.setup')}
              </button>
            </div>
          </div>
          <div className='right'>
            {/* <img src={`/img/top-img.${pngFile}`} alt='top img' loading='lazy' /> */}           
            <video controls autoPlay muted loop playsInline>
              <source src={`/video/harry.mp4`} type="video/mp4"/>
            </video>
          </div>
        </div>
      </section>
      <section className='home-partner'>
        <div className='main-wrap'>
          <h2 className='home-partner-title'>{t('home.partner.title')}</h2>
          <Swiper
            className='mySwiper'
            modules={[Autoplay]}
            autoplay={{
              disableOnInteraction: false,
            }}
            slidesPerView='4'
            spaceBetween={0}
            breakpoints={{
              768: { slidesPerView: 5 },
              1140: { slidesPerView: 8 },
            }}
          >
            {providerList.map((pd) => (
              <SwiperSlide key={`home-partner-${pd}`} className='home-partner-item'>
                <img src={`/img/provider/${pd}.webp`} alt={pd} loading='lazy' />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
      <section className='home-feature'>
        <video playsInline autoPlay muted loop>
          <source src={mp4File} type='video/mp4' />
        </video>
        <div className='video-mask'></div>
        <div className='main-wrap'>
          <div className='feature-title'>{t('home.feature.title')}</div>
          <p className='feature-desc'>
            {t('home.feature.desc')}
            <br />
            {t('home.feature.desc2')}
          </p>
          <div className='feature-list'>{featureRenderer()}</div>
        </div>
      </section>
      <section className='home-about'>
        <div className='main-wrap'>
          <div className='about-title'>{t('home.about.title')}</div>
          <div className='about-desc'>{t('home.about.desc')}</div>
          <ul className='about-list'>
            <li className='about-item'>
              <div className='item-img'>
                <img src={`/img/about1.svg`} alt='about1' loading='lazy' />
              </div>
              <div className='item-main-content'>
                <h4 className='item-title'>{t('home.about1.title')}</h4>
                <p className='item-desc'>{t('home.about1.desc')}</p>
              </div>
            </li>
            <li className='about-item'>
              <div className='item-img'>
                <img src={`/img/about2.svg`} alt='about2' loading='lazy' />
              </div>
              <div className='item-main-content'>
                <h4 className='item-title'>{t('home.about2.title')}</h4>
                <p className='item-desc'>{t('home.about2.desc')}</p>
              </div>
            </li>
            <li className='about-item'>
              <div className='item-img'>
                <img src={`/img/about3.svg`} alt='about3' loading='lazy' />
              </div>
              <div className='item-main-content'>
                <h4 className='item-title'>{t('home.about3.title')}</h4>
                <p className='item-desc'>{t('home.about3.desc')}</p>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section className='home-invite'>
        <video playsInline autoPlay muted loop>
          <source src={mp4File} type='video/mp4' />
        </video>
        <div className='video-mask'></div>
        <div className='main-wrap'>
          <h3>
            {t('home.invite.title1')}
            <br />
            {t('home.invite.title2')}
          </h3>
          <p>{t('home.invite.desc')}</p>
        </div>
      </section>
      <section id='homeDemo' className='home-demo'>
        <div className='main-wrap'>
          <h2 className='home-demo-title'>{t('home.demo.title')}</h2>
          <p className='home-demo-desc'>{t('home.demo.desc')}</p>
          <div className='home-demo-main'>
            <div className='mobile-frame'>
              <img src={`/img/mobile-frame.${pngFile}`} alt='mobile-frame' loading='lazy' />
              <video playsInline autoPlay muted loop>
                <source src='/img/demo_video.mp4' type='video/mp4' />
              </video>
            </div>
            <div className='home-demo-list'>{demoRenderer()}</div>
          </div>
        </div>
      </section>
      <section id='homeDemo' className='home-press'>
        <div className='main-wrap'>
          <h2 className='home-press-title'>{t('home.press.title')}</h2>
          
          <div className='home-news-main'>
          <div className='home-news-list'
              onClick={() => window.open('https://sigma.world/news/sigma-europe-b2b-anniversary-awards-celebrates-new-events-for-2025/')}
            >
              <h3>SiGMA Europe B2B Awards
Industry Rising Star 2024 – K36</h3>
              <div className='home-news-src'>
                <img src={`https://sigma.world/app/uploads/2024/01/sigma-square-logo-96x96.jpg`} alt='SiGMA' loading='lazy' />
                SiGMA
              </div>
              <div className='home-news-kv'>
                <img src={`/img/aw-rs-e.jpg`} alt='' loading='lazy' />
              </div>
            </div>

            <div className='home-news-list'
              onClick={() => window.open('https://issuu.com/sigmapublication/docs/sigma_issue_26')}
            >
              <h3>AI: THE FIFTH INDUSTRIAL REVOLUTION</h3>
              <div className='home-news-src'>
                <img src={`https://sigma.world/app/uploads/2024/01/sigma-square-logo-96x96.jpg`} alt='SiGMA' loading='lazy' />
                SiGMA
              </div>
              <div className='home-news-kv'>
                <img src={`/img/aw-ai.jpg`} alt='' loading='lazy' />
              </div>
            </div>
            
            <div className='home-news-list'
            onClick={() => window.open('https://sigma.world/news/sigma-asia-awards-brings-glamour-to-manila-gaming-event/')}
            >
              <h3>K36, the winner of the INDUSTRY RISING STAR 2024</h3>
              <div className='home-news-src'>
                <img src={`https://sigma.world/app/uploads/2024/01/sigma-square-logo-96x96.jpg`} alt='SiGMA' loading='lazy' />
                SiGMA
              </div>
              <div className='home-news-kv'>
                <img src={`/img/aw-rs.jpg`} alt='' loading='lazy' />
              </div>
            </div>
            {/* <div className='home-news-list'
            onClick={() => window.open('https://www.linkedin.com/posts/sigma-world_sigmaasia-speech-gamechanger-ugcPost-7204041549029060610-1Tbg/')}>
              <h3>Dive into the realm of AI solutions in the iGaming platform</h3>
              <div className='home-news-src'>
                <img src={`https://sigma.world/app/uploads/2024/01/sigma-square-logo-96x96.jpg`} alt='SiGMA' loading='lazy' />
                SiGMA
              </div>
              <div className='home-news-kv'>
                <img src={`https://media.licdn.com/dms/image/D4D10AQFT7NtAA83UhA/image-shrink_800/0/1717577340743?e=1718956800&v=beta&t=T5LQokGRglVQ1jXO2llQNpzq1DG08mY1VWr5n5lqJd4`} alt='' loading='lazy' />
              </div>
            </div> */}
          </div>
          <div className='button-group'>
            <button className='k-btn btn-l btn-primary' onClick={() => desktopNavigationHandle('/News')}>{t('home.press.more')}</button>
          </div>
        </div>
      </section>
      
      <section className='home-contact'>
        <div className='main-wrap'>
          <div className='home-contact-main'>
            <h3>{t('home.contact.title')}</h3>
            <p>{t('home.contact.desc')}</p>
            <div className='button-group'>
              <button className='k-btn btn-l btn-primary' onClick={() => desktopNavigationHandle('/ContactUs')}>
                {t('home.contact.btn.contact')}
              </button>
              <button
                className='k-btn btn-l btn-outline-primary'
                onClick={() => {
                  desktopNavigationHandle('/Template');
                  dispatch(UpdateTemplateNavigation('mobile'));
                  dispatch(UpdateTemplateWindow(false));
                }}
              >
                {t('home.contact.btn.setup')}
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withTranslation()(Home);
