import React from 'react';
// import useShallowEqual from 'libraries/hooks/useShallowEqual';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const InsightForge = ({ i18n, t }) => {
  // const { isSupportWebp } = useShallowEqual((state) => ({
  //   isSupportWebp: state.isSupportWebp,
  // }));
  const currentLocal = i18n.language;
  // const pngFile = isSupportWebp ? 'webp' : 'png';
  const navigate = useNavigate();
  const desktopNavigationHandle = (route) => {
    navigate(route);
    window.scrollTo(0, 0);
  };
  const demoRenderer = () => {
    var demoList = [];
    for (var i = 1; i < 6; i++) {
      demoList.push(
        <div key={`looker-demo${i}-item`} className='demo-item'>
          <img src={`/img/if-0${i}-en.jpg`} alt="" loading='lazy' />
          <div className='main-content'>
            <h4>{t(`looker.demo${i}.title`)}</h4>
            <p>{t(`looker.demo${i}.desc`)}</p>
          </div>
        </div>
      );
    }
    return demoList;
  };
  return (
    <div className='page-looker'>
      <div className='k-btn-toggle-group'>
        <div className='k-btn-toggle-wrap'>
          <div className='k-btn-toggle' onClick={() => desktopNavigationHandle('/SportsPlatform')}>{t('nav.sports.platform')}</div>
          <div className='k-btn-sepline'></div>
          
          <div className='k-btn-toggle' onClick={() => desktopNavigationHandle('/LightningPlatform')}>{t('nav.lightning.platform')}</div>
          <div className='k-btn-sepline'></div>
          <div className='k-btn-toggle' onClick={() => desktopNavigationHandle('/GlobalPlatform')}>{t('nav.global.platform')}</div>
          <div className='k-btn-sepline'></div>
          <div className='k-btn-toggle active'>{t('nav.InsightForge')}</div>
        </div>
      </div>
      <section className='looker-top'>
        <div className='main-wrap'>
          <div className='small-text-tag'>{t('looker.top.tag')}</div>
          <h1><strong>{t('looker.top.title2')}</strong>{t('looker.top.title1')}</h1>
          {/* <p>{t('looker.top.desc')}</p> */}
          {/* <p className='desc-mobile'>{t('looker.top.desc.mobile')}</p> */}
        </div>
        {/* <div className='kv-left'><img src={`/img/kv-if02.${pngFile}`} alt="kv-if02" loading='lazy' /></div>
        <div className='kv-right'><img src={`/img/kv-if03.${pngFile}`} alt="key-mobile" loading='lazy' /></div> */}
        <div className='kv-looker'  data-lang={`${currentLocal}`}>
          <img src={`/img/if-kv.webp`} alt="looker" loading='lazy' />
        </div>
      </section>
      <section className='looker-demo'>
        {/* <div className="video-bg">
          <video playsInline autoPlay muted loop>
            <source src='/img/info.mp4' type='video/mp4' />
          </video>
        </div> */}
        <div className="looker-demo-cont">
        <h2 className='home-demo-title mobile-show'>{t('looker.demo.title')}</h2>
        <p className='home-demo-desc mobile-show'>{t('looker.demo.desc')}</p>
        <div className='main-wrap'>
          <div className='left'>
            <h2 className='home-demo-title'>{t('looker.demo.title')}</h2>
            <p className='home-demo-desc'>{t('looker.demo.desc')}</p>
            <div className='home-demo-list' data-lang={`${currentLocal}`}>{demoRenderer()}</div>
          </div>
        </div>
        </div>
      </section>
      
    </div>
  );
};

export default withTranslation()(InsightForge);
